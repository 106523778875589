import { NextLink, Translate, useTranslate } from '~/i18n';
import { ArrowUpLeft, ArrowUpRight } from '~/svgs/navbar';
import links from './footer_links';
import {
   FacebookIcon,
   InstagramIcon,
   SnapchatIcon,
   TwitterIcon,
   TikTokIcon,
   YouTubeIcon,
   LinkedInIcon,
} from '~/svgs/footer';
import Container from '~/components/common/container';
// import Input from '~/components/common/input';
import cn from 'classnames';
import { HYArLogo, HYLogo } from '~/svgs/common';
import HyproBanner from '~/components/home-page/hypro-banner';
import { homepageSellYourExpFooterBtnEvent } from '~/utils/mixpanel-events/homepage';

export const socials = [
   { href: 'https://www.facebook.com/jarirbookstore', icon: <FacebookIcon />, label: 'Facebook' },
   { href: 'https://twitter.com/jarirbookstore', icon: <TwitterIcon />, label: 'Twitter' },
   { href: 'https://www.youtube.com/user/jarirtube', icon: <YouTubeIcon /> },
   {
      href: 'https://www.instagram.com/jarirbookstore',
      icon: <InstagramIcon />,
      label: 'Instagram',
   },
   { href: 'https://www.linkedin.com/company/jarir-bookstore', icon: <LinkedInIcon /> },

   {
      href: 'https://www.snapchat.com/add/jarirbookstore',
      icon: <SnapchatIcon />,
      label: 'Snapchat',
   },

   { href: 'https://www.tiktok.com/@jarirbookstore?from=sw&lang=en', icon: <TikTokIcon /> },
];

const JarirWebsiteFooter = () => {
   const { dir, lang } = useTranslate();
   return (
      <footer className="block" style={{ backgroundColor: '#313131' }}>
         <div>
            <div className="flex flex-col lg:flex-row " style={{ color: '#aeafb1' }}>
               <Container>
                  {/* <JarirRedBrand className="mx-auto mt-8 mb-14" /> */}
                  <div className="flex flex-wrap mt-10 lg:flex-nowrap">
                     <div className="flex flex-wrap flex-grow mb-4">
                        {links.map((link, index) => (
                           <div key={index} className="w-full mb-8 sm:w-1/2 xl:w-1/4">
                              <div>
                                 <span className="block px-4 pb-1 text-lg font-bold ">
                                    <div className="py-2 ">{link.title}</div>
                                 </span>
                                 <ul className="space-y-3 text-sm list-none text-primary-color">
                                    {(link.children as any[]).map(
                                       (item: any, child_idx: number) => (
                                          <li key={child_idx}>
                                             {item.external && (
                                                <a
                                                   className={cn(
                                                      'relative inline-flex items-center px-4 py-1 rounded-full text-white',
                                                      item.className
                                                   )}
                                                   href={`https://jarir.com/${
                                                      lang === 'sa-ar'
                                                         ? item.href
                                                         : lang + '/' + item.href
                                                   }`}
                                                   target="_blank"
                                                   rel="noreferrer noopener">
                                                   <span>
                                                      {item.title}{' '}
                                                      {dir === 'ltr' ? (
                                                         <ArrowUpRight className="inline-block" />
                                                      ) : (
                                                         <ArrowUpLeft className="inline-block" />
                                                      )}
                                                   </span>
                                                </a>
                                             )}
                                             {!item.external && (
                                                <NextLink
                                                   className="relative inline-flex items-center px-4 py-1 text-white rounded-full"
                                                   href={item.href}>
                                                   {item.title}
                                                </NextLink>
                                             )}
                                          </li>
                                       )
                                    )}
                                 </ul>
                              </div>
                           </div>
                        ))}
                     </div>
                     <div className="lg:px-6">
                        {/* <span className="block mb-6 text-lg font-bold">
                           <Translate id="jarir:join our mailing list" />
                        </span>
                        <form onSubmit={e => e.preventDefault()}>
                           <Input
                              className="rounded-default"
                              type="email"
                              inputMode="email"
                              placeholder={translate({ id: 'jarir:email' })}
                           />
                           <button
                              style={{ background: '#EE3436' }}
                              className="px-5 py-3 mt-3 text-white rounded-default focus:outline-none focus:ring-1 ">
                              <Translate id="jarir:susbscribe" />
                           </button>
                        </form> */}
                        <span className="block px-4 mt-2 mb-6 text-lg font-bold sm:px-0">
                           <Translate id="jarir:follow jarir" />
                        </span>
                        <div className="flex mb-10 space-x-3 rtl:space-x-reverse">
                           {socials.map(({ href, icon, label }, idx) => (
                              <a
                                 href={href}
                                 target="_blank"
                                 aria-label={label}
                                 rel="noreferrer noopener"
                                 key={idx}
                                 className="flex items-center justify-center w-8 h-8 transition duration-150 border border-white border-double rounded-full cursor-pointer sm:w-10 sm:h-10">
                                 {icon}
                              </a>
                           ))}
                        </div>

                        <HyproBanner footer small trackEvent={homepageSellYourExpFooterBtnEvent} />
                     </div>
                  </div>
                  <hr />
                  <div className="my-12">
                     <div className="text-center">
                        <div className="flex items-center justify-center px-4 text-sm font-medium">
                           <span className="ltr:mr-3 rtl:ml-3 ">
                              <Translate id="jarir:powered with" />
                           </span>
                           <div className="transition-transform duration-150 transform hover:scale-110">
                              <a href={`https://www.halayalla.com/${lang}`}>
                                 {lang === 'sa-ar' ? (
                                    <HYArLogo style={{ height: 45, width: 75 }} />
                                 ) : (
                                    <HYLogo style={{ height: 45, width: 75 }} />
                                 )}
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </Container>
            </div>
            <div style={{ backgroundColor: '#e6e6e6' }} className="py-7">
               <Container>
                  <div className="flex justify-between">
                     <div></div>
                     <div className="text-xs ltr:text-right rtl:text-left">
                        <div className="mb-1 space-x-1 rtl:space-x-reverse">
                           <a
                              href={`https://www.jarir.com${
                                 lang === 'sa-ar' ? '' : `/${lang}`
                              }/privacy-policy`}
                              target="_blank"
                              rel="noreferrer noopener"
                              className="underline">
                              <Translate id="jarir:privacy policy" /> |
                           </a>
                           <a
                              href={`https://www.jarir.com${
                                 lang === 'sa-ar' ? '' : `/${lang}`
                              }/terms-of-service`}
                              target="_blank"
                              rel="noreferrer noopener"
                              className="underline">
                              <Translate id="jarir:terms and service" />
                           </a>
                        </div>
                        <div>
                           <Translate id="jarir:privacy policy content" />
                        </div>
                     </div>
                  </div>
               </Container>
            </div>
         </div>
      </footer>
   );
};

export default JarirWebsiteFooter;
